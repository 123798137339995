import { Box } from "@chakra-ui/react";
import { darkGray, gray } from "./functions/Colors";
import UseGetSize from "./functions/UseGetSize";

import styles from "./css/hexagon.css";
import Header from "./header";
import Body from "./body";

export const Home = () => {
  const { height, width } = UseGetSize();

  return (
    <Box
      w={width}
      h={height}
      position="relative"
      overflow="hidden"
      bg={darkGray}
    >
      <Box
        className="main"
        position="absolute"
        left={-50}
        top={-50}
        w="125%"
        h="125%"
        zIndex={10}
      >
        <Box className="container">
          {Array.from({ length: Math.floor(width / 2) }).map((_, index) => (
            <div key={index} style={{ zIndex: 10, position: "relative" }}></div>
          ))}
        </Box>
      </Box>

      <Header />
      <Body />
    </Box>
  );
};

export default Home;
