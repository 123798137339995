import { Box, Image, Tab, TabList, Tabs } from "@chakra-ui/react";
import { lightGray, yellow } from "./functions/Colors";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import getImages from "./functions/loadImages";
import UseGetSize from "./functions/UseGetSize";

const handleDragStart = (e) => e.preventDefault();

const GalleryPage = ({ height, width }) => {
  const imageDirectory = "test1";
  const imagePaths = getImages(imageDirectory);

  const items = Object.keys(imagePaths).map((src) => {
    //const imagePath = `./images/${imageDirectory}/${src}`; // Construct the image path
    const ratio = 1;

    return (
      <Image
        key={src} // Use src as a unique key for each image
        src={imagePaths[src]}
        onDragStart={handleDragStart}
        role="presentation"
        height={height * 0.9}
        objectFit="contain"
        //h="100%"
      />
    );
  });

  const responsive = {
    0: {
      items: 2,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };

  const Gallery = () => (
    <AliceCarousel
      mouseTracking
      items={items}
      autoPlay={true}
      infinite={true}
      responsive={responsive}
      //autoHeight={false}
      autoWidth={true}
      //disableButtonsControls={true}
      animationDuration={1000}
      autoPlayInterval={1000}
      onInitialized={() => console.log("this object was initialized")}
    />
  );

  // tabs
  const tabNames = ["one", "two", "three", "four", "five"];

  return (
    <>
      <Box h={height} w="100%">
        <Box position="relative" h="10%" w="100%">
          <Box h="100%" w="80%" left={(width / 10) * 1.5} position="relative">
            <Tabs
              isFitted
              variant="unstyled"
              color={lightGray}
              size={`${height}px`}
            >
              <TabList>
                {tabNames.map((tab) => (
                  <Tab
                    _selected={{ textColor: yellow }}
                    _hover={{ color: yellow }}
                    fontSize={`${height / 20}`}
                    fontFamily="LonelyCake"
                    textAlign="center"
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
        </Box>
        <Box
          position="relative"
          h="90%"
          w="80%"
          left={(width / 10) * 1.5}
          overflow="hidden"
        >
          <Box h="100%">
            <Gallery />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GalleryPage;
