import React from "react";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  console.log(images);
  return images;
};

const getImages = (name) => {
  console.log(name);
  if (name === "test1") {
    return importAll(
      require.context("../../images/test1/", false, /\.(png|jpe?g|svg)$/),
    );
  }

  return [];
};

export default getImages;
